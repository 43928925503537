import React, { Component } from "react";
import Page from "../containers/page";

import { Container, Row, Col, Input } from "reactstrap";

import Isvg from "react-inlinesvg";

import FormBuilder from "../components/forms/formBuilder";
import { required } from "../components/forms/validation";
import Search from "../components/search";

import notification from "../assets/svg/notification.svg";
import profile from "../assets/svg/profile-image.svg";
import moreIcon from "../assets/svg/more.svg";
import closeIcon from "../assets/svg/x.svg";
import rightIcon from "../assets/svg/arrow-right.svg";

import gallery1 from "../assets/images/g1.jpg";
import uploadButton from "../assets/svg/upload.svg";

import ListBuilder from "../components/listBuilder";
import DeleteModal from "../components/deleteModal";
import ErrorModal from "../components/errorModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../helpers/linkHelper";
import { API_ENDPOINT } from "../constants";

import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      areas: [],
      total: 0,
      loading: true,
      category: [],
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
              entries: 10,
              page: 0,
            })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
              entries: 10,
              page: 0,
            })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  insertOrUpdate = (data) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!this.props[0].match.params.id) {
          fetch(API_ENDPOINT + "/data/category/new", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
                this.props[0].history.push("/category");
              }
            });
        } else {
          fetch(
            API_ENDPOINT + "/data/category/" + this.props[0].match.params.id,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
                Authorization:
                  typeof localStorage !== "undefined"
                    ? `Bearer ${localStorage.getItem("authToken")}`
                    : null,
              },
              body: JSON.stringify(data),
            }
          )
            .then((res) => res.json())
            .then((result) => {
              this.props[0].history.push("/category");
            });
        }
      }
    );
  };

  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + "/data/prize/delete" + id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get();
          });
      }
    );
  };

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }
    console.log(this.props);
    return (
      <div className="page">
        <Container
          className={
            this.props.acitveSidebar ? "dashboard" : "dashboard padd-change"
          }
        >
          <FormBuilder
            onSubmit={(data) => this.insertOrUpdate(data)}
            initialValues={this.state.initialValues}
            fields={[
              {
                type: "row",
                children: [
                  {
                    type: "col",
                    width: {
                      lg: 12,
                    },
                    children: [
                      {
                        type: "div",
                        className: "main-form",
                        children: [
                          {
                            type: "row",
                            children: [
                              //HEADER
                              {
                                //naslov forme
                                type: "col",
                                width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12,
                                },
                                children: [
                                  {
                                    type: "div",
                                    className: "form-header",
                                    children: [
                                      {
                                        type: "h5",
                                        className: "component-header",
                                        text: "Dodavanje kategorije",
                                      },
                                      {
                                        type: "switch",
                                        label: "Homepage",
                                        name: "homepage",
                                      },
                                    ],
                                  },
                                ],
                              },
                              //END HEADER
                              //FORMA za unos vijesi
                              {
                                type: "col",
                                width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12,
                                },
                                children: [
                                  {
                                    type: "text",
                                    name: "categoryName",
                                    label: "Naziv kategorije",
                                    validate: [
                                      required("Ime kategorije je obavezno!"),
                                    ],
                                  },
                                  {
                                    type: "div",
                                    className: "component subcategorie",
                                    children: [
                                      {
                                        type: "select",
                                        name: "parent",
                                        multiple: false,
                                        values: [
                                          { name: "Kategorije", value: null },
                                          ...this.state.category.map((item) => {
                                            return {
                                              name: item.categoryName,
                                              value: item._id,
                                            };
                                          }),
                                        ],
                                        label: "Nadkategorija",
                                      },
                                    ],
                                  },
                                  {
                                    type: "text",
                                    name: "catColor",
                                    label: "Boja kategorije",
                                  },
                                ],
                              },

                              {
                                type: "col",
                                width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12,
                                },
                                children: [
                                  {
                                    type: "submit-button",
                                    className: "main-form-button button-1",
                                    text: this.props[0].match.params.id
                                      ? "Izmjeni kategoriju"
                                      : "Dodaj kategoriju",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  //END FORM za unos vijesti
                ],
              },
            ]}
          ></FormBuilder>
          {this.state.error ? (
            <ErrorModal
              isOpen={this.state.error}
              toggle={() => this.setState({ error: null })}
            >
              {this.state.error.translate(this.props.lang)}
            </ErrorModal>
          ) : null}
        </Container>
      </div>
    );
  }
}

const selector = formValueSelector("form");

export default connect(
  (state) => {
    return { menu: selector(state, "menu") };
  },
  {
    changeCategory: (value) => change("form", "category", value),
  }
)(Page(Form));
