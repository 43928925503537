import React, { Component } from "react";
import Page from "../containers/page";
import { Container, Row, Col, Input } from "reactstrap";
import Isvg from "react-inlinesvg";
import { encodeBase64, saveAs } from "@progress/kendo-file-saver";

import FormBuilder from "../components/forms/formBuilder";
import { required } from "../components/forms/validation";
import Search from "../components/search";

import notification from "../assets/svg/notification.svg";
import profile from "../assets/svg/profile-image.svg";
import moreIcon from "../assets/svg/more.svg";
import rightIcon from "../assets/svg/arrow-right.svg";

import ErrorModal from "../components/errorModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../helpers/linkHelper";
import { API_ENDPOINT } from "../constants";
import { Link } from "react-router-dom";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      areas: [],
      total: 0,
      loading: false,
      category: [],
      formSubmited: false,
      errorMessage: "",
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
              entries: 10,
              page: 0,
            })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
              entries: 10,
              page: 0,
            })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  insertOrUpdate = (data) => {
    const { category } = data;

    // Function to check if a string is a valid category ID
    const isValidCategoryId = (id) => {
      const objectIdRegex = /^[0-9a-fA-F]{24}$/;
      return objectIdRegex.test(id);
    };

    if (!isValidCategoryId(category)) {
      // Display an error message or handle invalid category ID
      this.setState({ errorMessage: "Invalid category ID" });
      return;
    }

    // Check if date and time are provided, otherwise use current date and time
    if (!data.date) {
      const currentDate = new Date();
      // Format date as "2023-12-02"
      data.date = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      // Format dateA as "02.12.2023"
      data.dateA = `${currentDate.getDate().toString().padStart(2, "0")}.${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${currentDate.getFullYear()}`;
    }

    if (!data.time) {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      data.time = `${hours}:${minutes}`; // set to current time in 24-hour format
    }

    this.setState({ errorMessage: "" });

    if (data.subcategory) {
      // If subcategory exists, update category to subcategory and remove subcategory from data
      data.category = data.subcategory;
      delete data.subcategory;
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!this.props[0].match.params.id) {
          fetch(API_ENDPOINT + "/data/news/new", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
                this.props[0].history.push("/newsWithQuery");
              }
            });
        } else {
          fetch(API_ENDPOINT + "/data/news/" + this.props[0].match.params.id, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
                this.props[0].history.push("/newsWithQuery");
              }
            });
        }
      }
    );
  };

  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + "/data/prize/" + id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get();
          });
      }
    );
  };

  render() {
    console.log("Error", this.state.errorMessage);
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }

    return (
      <div className="page">
        <Container
          className={
            this.props.acitveSidebar ? "dashboard" : "dashboard padd-change"
          }
        >
          {console.log(this.state.initialValues)}
          <Row>
            <Col lg="12">
              <FormBuilder
                onSubmit={(data) => {
                  if (!this.state.formSubmited) {
                    this.insertOrUpdate(data);
                    this.setState({
                      formSubmited: true,
                    });
                  }
                }}
                initialValues={this.state.initialValues}
                fields={[
                  {
                    type: "row",
                    children: [
                      {
                        type: "col",
                        width: {
                          lg: 9,
                        },
                        children: [
                          {
                            type: "div",
                            className: "main-form",
                            children: [
                              {
                                type: "row",
                                children: [
                                  //HEADER
                                  {
                                    //naslov forme
                                    type: "col",
                                    width: {
                                      lg: 2,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "div",
                                        className: "form-header",
                                        children: [
                                          {
                                            type: "h5",
                                            className: "component-header",
                                            text: "Dodavanje vijesti",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    //button-i i switch
                                    type: "col",
                                    width: {
                                      lg: 10,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "div",
                                        className: "form-header-buttons",
                                        children: [
                                          {
                                            type: "div",
                                            className: "form-title-buttons",
                                            children: [
                                              {
                                                type: "switch",
                                                label: "Aktivna",
                                                name: "active",
                                              },
                                              {
                                                type: "switch",
                                                label: "Video",
                                                name: "video",
                                              },
                                              {
                                                type: "switch",
                                                label: "Izdvojena",
                                                name: "segregate",
                                              },
                                              {
                                                type: "switch",
                                                label: "Udarna",
                                                name: "breaking",
                                              },
                                              {
                                                type: "button",
                                                className:
                                                  "main-form-button button-2",
                                                text: (
                                                  <a
                                                    href={
                                                      API_ENDPOINT +
                                                      "/vijesti/" +
                                                      (this.state.initialValues
                                                        ? this.state
                                                            .initialValues.alias
                                                        : null)
                                                    }
                                                    target="_blank"
                                                  >
                                                    Pregledaj vijest{" "}
                                                    <span>
                                                      {" "}
                                                      <Isvg
                                                        src={rightIcon}
                                                      />{" "}
                                                    </span>{" "}
                                                  </a>
                                                ),
                                                name: "alias",
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  //END HEADER
                                  //FORMA za unos vijesi
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "title",
                                        label: "Naslov vijesti",
                                        validate: [
                                          required("Naslov je obavezan!"),
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "textarea",
                                        name: "intro",
                                        label: "Uvodni tekst",
                                        validate: [
                                          required("Uvodni tekst je obavezan!"),
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "html",
                                        name: "details",
                                        label: "Detaljno",
                                        onChange: {},
                                        validate: [
                                          required(
                                            "Detaljan opis je obavezan!"
                                          ),
                                        ],
                                      },
                                    ],
                                  },

                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "source",
                                        label: "Izvor",
                                        validate: [
                                          required("Izvor je obavezan!"),
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "photo",
                                        label: "Foto",
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "embedVideo",
                                        label: "Embed video",
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "metaTags",
                                        label: "Meta tagovi",
                                      },
                                    ],
                                  },

                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "select",
                                        name: "category",
                                        multiple: false,
                                        values: [
                                          { name: "None", value: null },
                                          ...this.state.category
                                            .filter((item) => !item.parent)
                                            .map((item) => {
                                              return {
                                                name: item.categoryName,
                                                value: item._id,
                                              };
                                            }),
                                        ],
                                        label: "Kategorija",
                                        validate: [
                                          required("Kategorija je obavezna!"),
                                        ],
                                      },
                                    ],
                                  },

                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "select",
                                        name: "subcategory",
                                        multiple: false,
                                        values: [
                                          { name: "None", value: null },
                                          ...this.state.category
                                            .filter(
                                              (item) =>
                                                this.props.category &&
                                                this.props.category ==
                                                  item.parent
                                            )
                                            .map((item) => {
                                              return {
                                                name: item.categoryName,
                                                value: item._id,
                                              };
                                            }),
                                        ],
                                        label: "Podkategorija",
                                      },
                                    ],
                                  },
                                  //podkategorija !!!
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "submit-button",
                                        className: "main-form-button button-1",
                                        text: this.state.loading
                                          ? "Loading..."
                                          : this.props[0].match.params.id
                                          ? "Izmjeni vijesti"
                                          : "Dodaj vijesti",
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      //END FORM za unos vijesti
                      {
                        type: "col",
                        width: {
                          lg: 3,
                        },
                        children: [
                          {
                            //vrijeme objave
                            type: "div",
                            className: "component customer-age",
                            children: [
                              {
                                type: "h5",
                                text: "Vrijeme objave",
                                className: "component-header",
                              },
                              {
                                type: "date",
                                name: "date",
                              },
                              {
                                type: "time",
                                name: "time",
                              },
                            ],
                          },
                          {
                            //Slike
                            type: "div",
                            className: `component gallery`,
                            children: [
                              // {
                              //   type: 'h5',
                              //   className: 'component-header',
                              //   text: 'Fotografije',
                              // },
                              {
                                type: "gallery",
                                name: "images",
                                label: "Fotografije",
                                validate: [
                                  required("Fotografija je obavezna!"),
                                ],
                              },
                            ],
                          },

                          {
                            //Kljucne rijeci
                            type: "div",
                            className: "component key-words-box",
                            children: [
                              {
                                type: "h5",
                                text: "Kljucne rijeci",
                                className: "component-header",
                              },
                              {
                                type: "div",
                                className: "key-words",
                                children: [
                                  {
                                    type: "tags",
                                    name: "keywords",
                                    //className: 'words',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            //Kljucne rijeci
                            type: "div",
                            className: "component key-words-box",
                            children: [
                              {
                                type: "h5",
                                text: "Onemogući komentare",
                                className: "component-header",
                              },
                              {
                                type: "switch",
                                name: "commentsDisable",
                              },
                            ],
                          },
                          {
                            //Posalji notifikacije
                            type: "div",
                            className: "component push-notifications",
                            children: [
                              {
                                type: "h5",
                                className: "component-header",
                                text: "Pus notifikacije",
                              },
                              {
                                type: "button",
                                className: "main-form-button button-1",
                                text: (
                                  <>
                                    Posalji pus{" "}
                                    <span>
                                      {" "}
                                      <Isvg src={rightIcon} />{" "}
                                    </span>{" "}
                                  </>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              ></FormBuilder>
              {this.state.errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}
              {this.state.error ? (
                <ErrorModal
                  isOpen={this.state.error}
                  toggle={() => this.setState({ error: null })}
                >
                  {this.state.error.translate(this.props.lang)}
                </ErrorModal>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const selector = formValueSelector("form");

export default connect(
  (state) => {
    return { category: selector(state, "category") };
  },
  {
    changeCategory: (value) => change("form", "category", value),
  }
)(Page(Form));
